import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styled';

const DOCTOR_TYPE = {
  name: PropTypes.string.isRequired,
  registry: PropTypes.string.isRequired,
};

function Doctor({ name, registry }) {
  return (
    <S.Doctor>
      <S.Name>{name}</S.Name>
      <S.Registry>CRM-SP: {registry}</S.Registry>
    </S.Doctor>
  );
}
Doctor.propTypes = DOCTOR_TYPE;

export default function Doctors({ doctors }) {
  return (
    <S.Doctors>
      {doctors.map(({ name, registry }) => (
        <Doctor key={registry} name={name} registry={registry} />
      ))}
    </S.Doctors>
  );
}
Doctors.propTypes = {
  doctors: PropTypes.arrayOf(PropTypes.shape(DOCTOR_TYPE)).isRequired,
};
